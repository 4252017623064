#App {
  height: 100vh;
}

.tooltip-inner {
  --bs-tooltip-max-width: auto !important;
  text-align: initial;
}

.dark-bg {
  background-color: #15191d;
}

.clickable {
  cursor: pointer;
}

.navbar-brand {
  cursor: pointer;
}

.menu-icon {
  font-size: large;
  color: #00ff6d;
  padding: 1rem;
  cursor: pointer;
}

.menu-link {
  text-decoration: none;
  color: white;
  box-sizing: border-box;

  &:hover {
    background: #003314;
  }
}

.menu-link-active {
  background: #005e25;
  border-left: 3px solid #00ff6d;
}

.install-button:before {
  content: "+  ";
}

.gitlab-button {
  background-color: #e2432a;
  color: white !important;
  border-color: #e2432a;

  &:hover {
    background-color: #fc6d27 !important;
    border-color: #fc6d27 !important;
  }

  &:active {
    background-color: #fca326 !important;
    border-color: #fca326 !important;
  }

  &:before {
    content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-gitlab"><path d="M22.65 14.39L12 22.13 1.35 14.39a.84.84 0 0 1-.3-.94l1.22-3.78 2.44-7.51A.42.42 0 0 1 4.82 2a.43.43 0 0 1 .58 0 .42.42 0 0 1 .11.18l2.44 7.49h8.1l2.44-7.51A.42.42 0 0 1 18.6 2a.43.43 0 0 1 .58 0 .42.42 0 0 1 .11.18l2.44 7.51L23 13.45a.84.84 0 0 1-.35.94z"></path></svg>');
    display: inline-block;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    margin-right: 1em;
  }

  &:disabled {
    background-color: #8c929d;
    border-color: #8c929d;
  }
}

.button-spinner {
  width: 24px;
  height: 24px;
  vertical-align: middle;
}

.more-options-spinner {
  width: 16px;
  height: 16px;
}

.btn-primary {
  --bs-btn-bg: #00ff6d;
  --bs-btn-border-color: #009d43;
  --bs-btn-hover-bg: #009d43;
  --bs-btn-hover-border-color: #00a643;
  --bs-btn-active-bg: #008333;
  --bs-btn-active-border-color: #005e25;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-bg: #008139;
  --bs-btn-disabled-border-color: #008139;
  --bs-btn-disabled-color: #cbcbcb;
  color: black;
}

.nav-pills {
  --bs-nav-pills-link-active-bg: #008333;
}

.dropdown-menu {
  --bs-dropdown-link-active-bg: var(--bs-secondary-bg);
}

.btn-outline-primary {
  --bs-btn-color: #00ff6d;
  --bs-btn-border-color: #00ff6d;
  --bs-btn-hover-color: black;
  --bs-btn-hover-bg: #00ff6d;
  --bs-btn-hover-border-color: #00ff6d;
  --bs-btn-active-color: black;
  --bs-btn-active-bg: #00ff6d;
  --bs-btn-active-border-color: #005e25;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.form-check-input:checked {
  background-color: #00ff6d;
  border-color: #009d43;
}

.nav-link {
  --bs-nav-tabs-link-active-color: white;
  --bs-nav-link-color: var(--bs-heading-color);
  --bs-nav-link-hover-color: white;
}

.pagination {
  --bs-pagination-color: grey;
  --bs-pagination-hover-color: lightgrey;
  --bs-pagination-focus-color: lightgrey;
  --bs-pagination-focus-box-shadow: none;
}

.rmsc {
  --rmsc-hover: #292f34 !important;
  --rmsc-selected: #373e44 !important;
  --rmsc-border: var(--bs-border-color) !important;
  --rmsc-gray: #aaa !important;
  --rmsc-bg: var(--bs-body-bg) !important;
}

.dropdown-content {
  border: var(--rmsc-border) solid 1px;
  background-color: var(--rmsc-bg);
}

.loading-table {
  opacity: 0.45;
  pointer-events: none;
  user-select: none;
}

.latestTag {
  background-color: #198754;
  color: white;
}

.latestTag:checked,
option:checked {
  background-color: #BDD7FE !important;
  color: black !important;
}

.row-highlighted {

  &:hover > *, &:nth-of-type(odd):hover > * {
    box-shadow: inset 0 0 0 9999px #008135 !important;
  }

  & > * {
    box-shadow: inset 0 0 0 9999px #005e25 !important;
  }

  &:nth-of-type(odd) > * {
    box-shadow: inset 0 0 0 9999px #00732e !important;
  }
}

.button-release {
  position: relative;
}

.button-release::after {
  content: "";
  position: absolute;
  width: 100%;
  top: -0.5rem;
  left: -0.5rem;
  padding: 0.5rem;
  box-sizing: content-box;
  height: 100%;
}

.pagination {
  --bs-pagination-active-bg: #008333;
  --bs-pagination-active-border-color: #005e25;
}

.modal-80w {
  width: 80%;
  max-width: none !important;
}

input[type="checkbox"] {
  cursor: pointer;
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: white;
  width: 16px;
  height: 16px;
}

.more-actions {
  border: none !important;

  &::after {
    display: none !important;
  }
}

.translations-table th {
  min-width: 150px;
}

.translations-table th:first-child {
  min-width: 40px;
}

.translations-table th:nth-child(2) {
  min-width: 125px;
}

.translations-table table {
  position: relative;
  z-index: 0;
}

.translations-table tr > :first-child {
  position: sticky;
  left: 0;
  z-index: 1;
}

.translations-table tr > :first-child .dropdown-menu {
  margin-left: 8px;
}

.translations-table tr > :nth-child(3) {
  position: sticky;
  left: 40px;
}

.reset-input-button {
  display: none;
  cursor: pointer;
}

form:hover .reset-input-button {
  display: block;
}

form .edited-label {
  display: none;
  margin-left: auto;
  margin-right: .25rem;
  height: 1.3rem;
  align-self: flex-end;
}

form .edited .edited-label {
  display: inline-block;
}